import { AxiosRequestConfig } from 'axios';

export const withCSRF = (axiosConfig: AxiosRequestConfig = {}): AxiosRequestConfig => {
  const { headers = {} } = axiosConfig;
  const meta = document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement;
  const tokenFromMeta = meta ? meta.content : null;
  const tokenFromLocalStorage = window.localStorage.csrf;
  const token = tokenFromLocalStorage || tokenFromMeta;

  if (!token) return axiosConfig;

  return {
    ...axiosConfig,
    headers: {
      ...headers,
      'x-csrf-token': token,
    },
  };
};

export const vndAPI = {
  headers: {
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
  },
};
