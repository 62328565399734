import { THROTTLE_FOR_TIME, THROTTLE_LIMIT, THROTTLE_THRESHOLD_PERIOD } from './constants';

export class Throttler {
  private timeframeStart: Date;
  private sentToAPIWithinTimeframe: number;
  private exceededLimitAt: Date | undefined;

  constructor() {
    this.sentToAPIWithinTimeframe = 0;
    this.timeframeStart = new Date();
    this.exceededLimitAt = undefined;
  }

  /** Record that we are sending event to API. */
  public incrementSentToApi = () => (this.sentToAPIWithinTimeframe += 1);

  public calculateThrottling = (): boolean => {
    if (this.checkIfThrottled()) return true; // Already throttling, continue to.

    // If exceeding throttle limit, throttle.
    if (this.sentToAPIWithinTimeframe > THROTTLE_LIMIT) {
      this.exceededLimitAt = new Date();

      console.error(
        `METRICS - Throttling 🚨. User time: ${new Date()}, window start time: ${this.timeframeStart}, exceededLimitAt: ${this.exceededLimitAt}.`,
      );
      setTimeout(() => {
        throw new Error('METRICS - Throttling exceeded.');
      }, 0);

      return true;
    }

    return false;
  };

  /**
   * Function to check if we are throttled, return true if we are allowing caller to return early.
   *
   * Handles cleanup if we are no longer exceeding timeout (after )
   */
  public checkIfThrottled = (): boolean => {
    const currentTime = new Date();
    const throttleExpiry =
      this.exceededLimitAt && new Date(this.exceededLimitAt.getTime() + THROTTLE_FOR_TIME);
    const windowExpiry = new Date(this.timeframeStart.getTime() + THROTTLE_THRESHOLD_PERIOD);

    const withinThrottleTime = this.exceededLimitAt && currentTime < throttleExpiry;

    // If user should be throttled.
    if (withinThrottleTime) return true;

    // Check window, if it is expired, reset.
    if (currentTime > windowExpiry) {
      console.debug('METRICS - Throttle Window expired.');
      this.timeframeStart = new Date();
      this.sentToAPIWithinTimeframe = 0;
    }

    // When timeout is exceeded, but there is an exceededLimitAt - CLEANUP..
    if (!withinThrottleTime && this.exceededLimitAt) {
      this.exceededLimitAt = undefined;
      this.sentToAPIWithinTimeframe = 0;

      if (process.env.REACT_APP_ENV) {
        console.debug('METRICS - Throttle Timeout exceeded. Resetting counters.');
      }
    }

    return false;
  };
}
