import { MetricMetadata } from './types';
import { getUUID } from './utils';

/**
 * Class representing a newly captured user Metric.
 */
export class Metric {
  /** The type of the event i.e. Modal Opened (keys an entry) */
  public type: string;

  /** The data of the event i.e. the name of the modal (values an entry) */
  public data?: object;

  /** Additional context information surrounding the event */
  public context?: object;

  /** Metric metadata, currently timestamp and eventId for de-duping */
  public meta: MetricMetadata;

  constructor(eventName: string, extraDetails?: object) {
    this.type = eventName;
    this.data = extraDetails;
    this.context = {
      pageReferrer: document.referrer,
      timezone: this.getTimezone(),
    };
    this.meta = {
      eventId: getUUID(),
      timestamp: new Date().valueOf(),
    };
  }

  getTimezone() {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
      return null;
    }
  }

  toString() {
    return `{ type: "${this.type}", dataKeys: [${this.data ? Object.keys(this.data).join(', ') : ''}], timestamp: ${new Date(this.meta.timestamp).toISOString()} }`;
  }
}
