import axios from 'axios';
import type { DebouncedFunc } from 'lodash';
import debounce from 'lodash.debounce';
import { Metric } from './Metric';
import { batchMetrics } from './utils';
import { FLUSH_AT_COUNT, FLUSH_TIMEOUT } from './constants';

import { withCSRF, vndAPI } from './axiosSetup';
import { Throttler } from './Throttler';
import * as PensieveMetrics from './PensieveUtils';

/**
 * MetricsClient for tracking user metrics on the client-side.
 *
 * Singleton implementation ensures only one metrics client should exist on the page at a time.
 */
export class MetricsClient {
  private static instance: MetricsClient;
  #debounceFlushMetricsToApi: DebouncedFunc<() => void>;

  private throttler: Throttler;

  /**
   * In-memory store for the metrics before they're sent to the API. Should be pre-transformed
   *   to the shape expected by the api.
   *
   * See: https://quip-amazon.com/WCbpAOCqkDqH/Client-Metrics-Segment-Replacement#temp:C:EGV183ded6571c04ba88966c7b9d
   */
  private metricQueue: Metric[] = [];

  private constructor() {
    this.#debounceFlushMetricsToApi = debounce(this.flushMetricsToApi, FLUSH_TIMEOUT);
    document.addEventListener('visibilitychange', this.onVisiblityChange);

    this.throttler = new Throttler();
  }

  /** Method to set this up as a Singleton. */
  static getInstance() {
    if (this.instance) {
      // ensure track metric is exposed - for safety.
      window.trackMetric = this.instance.trackMetric;
    } else {
      // Create instance, expose track metric
      this.instance = new MetricsClient();
      window.trackMetric = this.instance.trackMetric;

      // Track Page View (when the Metrics Client has been initialised).
      PensieveMetrics.trackPageView();
    }
    return this.instance;
  }

  /**
   * A method to be called when an event occurs.
   *
   * Deals with adding metric to the event queue, after inflating the metric with additional
   *  information before transforming it. Also deals with calling and sending events to the API.
   *
   * See: https://quip-amazon.com/WCbpAOCqkDqH/Client-Metrics-Segment-Replacement#temp:C:EGV183ded6571c04ba88966c7b9d
   */
  private trackMetric = (eventName: string, extraDetails?: object) => {
    const newMetric = new Metric(eventName, extraDetails);

    if (this.throttler.checkIfThrottled()) {
      if (process.env.REACT_APP_ENV === 'dev') {
        console.debug('METRICS - Throttled - Event not added to metricQueue');
      }
      return;
    }

    if (process.env.REACT_APP_ENV === 'dev') {
      console.debug('METRICS - Adding new event to metricQueue', newMetric);
    }

    this.metricQueue.push(newMetric);

    // Immediately flush metrics if over count, otherwise send with debounce.
    if (this.metricQueue.length >= FLUSH_AT_COUNT) this.#immediatelyFlushMetrics();
    else this.#debounceFlushMetricsToApi();
  };

  /**
   * A method to be called when a condition is met to flush the metricQueue and send metrics to the API.
   *
   * See sending conditions: https://quip-amazon.com/WCbpAOCqkDqH/Client-Metrics-Segment-Replacement#temp:C:EGV8e6504c914dd4c84a2d3d2d97
   */
  private flushMetricsToApi = async () => {
    if (this.metricQueue.length === 0) return; // Todo: Debug log when in dev mode.

    const batches = batchMetrics(this.metricQueue);

    this.metricQueue = []; // Reset metricQueue, if events fail to send we'll add them back.
    await Promise.all(batches.map(this.#sendMetricsToApi));
  };

  #sendMetricsToApi = async (metrics: Metric[], batchNum: number) => {
    // Increment throttle counter, and check if we should start throttling.
    this.throttler.incrementSentToApi();
    const shouldThrottle = this.throttler.calculateThrottling();
    if (shouldThrottle) {
      console.debug(`Metrics in batch size: ${metrics.length}, queue: ${metrics}`);
      this.metricQueue = [];
      return;
    }

    if (process.env.REACT_APP_ENV === 'dev') {
      console.debug(`METRICS - (not) Sending events to API - batchNum: ${batchNum}`, metrics);
      return;
    }

    try {
      await axios.post('/report_event', { events: metrics }, withCSRF(vndAPI));
    } catch (error) {
      this.metricQueue = this.metricQueue.concat(metrics); // Re-add events to metricQueue.
      console.error('Error sending events to API', error);
    }
  };

  /**
   * A method which should be called when visiblity changes. When visiblity state changes to hidden we should flush metrics.
   *
   * See conditions: https://quip-amazon.com/WCbpAOCqkDqH/Client-Metrics-Segment-Replacement#temp:C:EGVedb08a8ce54745659625adcc7
   */
  private onVisiblityChange = async () => {
    if (document.visibilityState === 'visible' || this.metricQueue.length === 0) return;

    if (process.env.REACT_APP_ENV === 'dev') {
      console.debug('METRICS - Visiblity changes to hidden, would send events.');
    }

    this.#debounceFlushMetricsToApi.cancel();

    try {
      await fetch('/report_event', {
        keepalive: true, // Critical - allows request to outlive the page.
        method: 'post',
        headers: withCSRF(vndAPI)?.headers as HeadersInit,
        body: JSON.stringify({ events: this.metricQueue }),
      });
      this.metricQueue = [];
    } catch (error: any) {
      console.error('Error sending events to API', error);
    }
  };

  #immediatelyFlushMetrics = () => {
    this.#debounceFlushMetricsToApi.cancel(); // Cancel any pending debounce.
    this.flushMetricsToApi(); // Flush metrics to API.
  };

  /**
   * Exposes the trackMetric method to the window object so that it can be called from the client.
   */
  static exposePensieve() {
    if (process.env.REACT_APP_ENV === 'dev') {
      console.debug('METRICS - Exposing metrics');
    }

    this.getInstance();
    window.PensieveMetrics = PensieveMetrics;
  }
}
