const SECOND_MULTIPLIER = 1000;
const MINUTE_MULTIPLIER = 60;

/**
 * Debounce timeout until events are flushed to the API.
 */
export const FLUSH_TIMEOUT = 10 * SECOND_MULTIPLIER;

/**
 * The number of events to collectbefore flushing to the API.
 */
export const FLUSH_AT_COUNT = 20;

/**
 * The number of events to batch when sending to the API.
 */
export const BATCH_SIZE = 50;

/**
 * THROTTLING.
 *
 * Note: This should be adjusted when patronus is setup, as the throttling can be done on the server
 * side - if we get 429 back from the server then we should implement client throttle.
 */

/**
 * The period of time we check for the throttling logic. If the threshold is exceeded within
 * this timeframe, we throttle the user.
 */
export const THROTTLE_THRESHOLD_PERIOD = 2 * MINUTE_MULTIPLIER * SECOND_MULTIPLIER;

/**
 * The amount of time we throttle the client for when the THROTTLE_LIMIT is exceeded within
 *  the THROTTLE_THRESHOLD_PERIOD.
 *
 * 15 MINUTES.
 */
export const THROTTLE_FOR_TIME = 15 * MINUTE_MULTIPLIER * SECOND_MULTIPLIER;

/**
 * To use to set the threshold as to when we are throttling the client.
 *
 * Should be increased if we find this is regularly being exceeded, and the clietn is behaving
 *  as expected - as events can be flushed early if lots of events come in.
 */
const THRESHOLD_ESTIMATE_MULTIPLER = 4;

/**
 * Throttle limit for the API.
 *
 * Logic: We send events every 10 seconds (if there is anything to send), so we should have
 *  30 events per client in a 5 minute period. We will exceed this when lots of events come in
 *  so we are allowing a multipler for the threshold period.
 */
export const THROTTLE_LIMIT = 30 * THRESHOLD_ESTIMATE_MULTIPLER;
