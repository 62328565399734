import { Metric } from './Metric';
import { BATCH_SIZE } from './constants';

/**
 * Helper to split the metrics queue into batches of the defined BATCH_SIZE.
 * @param eventsQueue Currently accrued unsent metrics.
 */
export const batchMetrics = (eventsQueue: Metric[]) => {
  const batches: Metric[][] = [];
  for (let i = 0; i < eventsQueue.length; i += BATCH_SIZE) {
    batches.push(eventsQueue.slice(i, i + BATCH_SIZE));
  }
  return batches;
};

/**
 * Backup UUID generator, likely to be less unique.
 *
 * Using as a polyfill for old browsers.
 */
const backupUUID = () =>
  '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: any) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );

/**
 * Generates a random UUID, tries to use crypto module when available.
 *
 * Otherwise, uses custom function above to create a UUID - though will be less consistent in
 * format, and less optimised for speed and likely to be more clashes than with randomUUID.
 */
export const getUUID = () => crypto.randomUUID ? crypto.randomUUID() : backupUUID();
